var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{scopedSlots:_vm._u([{key:"navbar-title",fn:function(){return [_vm._v(" Добавить ")]},proxy:true}])},[_c('CreateFormLayout',{scopedSlots:_vm._u([{key:"title__text",fn:function(){return [_vm._v(" Новая заявка ")]},proxy:true},{key:"title-flex-right",fn:function(){return [_c('div')]},proxy:true},{key:"btns",fn:function({ handleSubmit }){return [_c('a',{staticClass:"btn btn_color_white",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Отмена ")]),_c('v-btn',{staticClass:"btn btn-green",attrs:{"type":"submit"},on:{"click":function($event){return handleSubmit(_vm.createTask)}}},[_vm._v(" Сохранить ")])]}}])},[[_c('div',[_c('FormInputBlock',[_c('ValidationInputField',{attrs:{"rules":"required","label":"Тема","validate-name":"тема"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('ValidationSelectField',{attrs:{"items":[
              { text: 'Добавление контроллера', value: 'add' },
              { text: 'Ремонт', value: 'repair' }
            ],"rules":"required","label":"Тип заявки","validate-name":"тип заявки"},model:{value:(_vm.task_type),callback:function ($$v) {_vm.task_type=$$v},expression:"task_type"}}),_c('ValidationSelectField',{attrs:{"items":[
              { text: 'Высокий', value: 1 },
              { text: 'Обычный', value: 2 }
            ],"rules":"required","label":"Приоритет","validate-name":"приоритет"},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}}),_c('ValidationAutocompleteField',{attrs:{"search-function":_vm.objectSearch,"rules":"required","label":"Объект","return-object":false,"validate-name":"объект"},model:{value:(_vm.object),callback:function ($$v) {_vm.object=$$v},expression:"object"}}),_c('ValidationAutocompleteField',{attrs:{"search-function":_vm.controllerSearch,"label":"Контроллер","rules":"required","return-object":false,"validate-name":"контроллер"},model:{value:(_vm.controller),callback:function ($$v) {_vm.controller=$$v},expression:"controller"}}),_c('ValidationDateField',{attrs:{"rules":"required","label":"Срок выполнения","validate-name":"срок выполнения"},model:{value:(_vm.deadline_at),callback:function ($$v) {_vm.deadline_at=$$v},expression:"deadline_at"}}),_c('ValidationTextareaField',{attrs:{"label":"Текст комментария","validate-name":"текст комментария"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)]],2),_c('v-overlay',{attrs:{"value":_vm.loading,"z-index":"9999"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }