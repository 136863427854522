<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>

    <CreateFormLayout>
      <template v-slot:title__text>
        Новая заявка
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormInputBlock>
            <ValidationInputField
              rules="required"
              v-model="title"
              label="Тема"
              validate-name="тема"
            ></ValidationInputField>
            <ValidationSelectField
              v-model="task_type"
              :items="[
                { text: 'Добавление контроллера', value: 'add' },
                { text: 'Ремонт', value: 'repair' }
              ]"
              rules="required"
              label="Тип заявки"
              validate-name="тип заявки"
            ></ValidationSelectField>

            <ValidationSelectField
              v-model="priority"
              :items="[
                { text: 'Высокий', value: 1 },
                { text: 'Обычный', value: 2 }
              ]"
              rules="required"
              label="Приоритет"
              validate-name="приоритет"
            ></ValidationSelectField>

            <ValidationAutocompleteField
              v-model="object"
              :search-function="objectSearch"
              rules="required"
              label="Объект"
              :return-object="false"
              validate-name="объект"
            />

            <ValidationAutocompleteField
              v-model="controller"
              :search-function="controllerSearch"
              label="Контроллер"
              rules="required"
              :return-object="false"
              validate-name="контроллер"
            />
            <ValidationDateField
              v-model="deadline_at"
              rules="required"
              label="Срок выполнения"
              validate-name="срок выполнения"
            ></ValidationDateField>
            <ValidationTextareaField
              v-model="description"
              label="Текст комментария"
              validate-name="текст комментария"
            ></ValidationTextareaField>
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(createTask)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import ValidationTextareaField from "@/components/ValidationField/ValidationTextareaField";
import ValidationDateField from "@/components/ValidationField/ValidationDateField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import { getObjectsRequest } from "@/helpers/api/objects";
import { getControllersRequest } from "@/helpers/api/controllers";
import moment from "moment";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CreateServiceTaskPage",
  components: {
    FormInputBlock,
    CreateFormLayout,
    MainLayout,
    ValidationSelectField,
    ValidationInputField,
    ValidationAutocompleteField,
    ValidationDateField,
    ValidationTextareaField
  },

  data() {
    return {
      loading: false,
      newDate: moment().format("YYYY-MM-D"),
      objectSearch: async value => {
        const id = this.$store.getters.getCurrentOrganization;
        const isService = [50].includes(this.$store.getters.getCurrentRole);
        const pageOrgId = this.$route.params.serviceOrganizationId;
        return (
          await getObjectsRequest({
            query: {
              search: value,
              service_organization: isService ? id : pageOrgId
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      controllerSearch: async value => {
        const id = this.$store.getters.getCurrentOrganization;
        const isService = [50].includes(this.$store.getters.getCurrentRole);
        const pageOrgId = this.$route.params.serviceOrganizationId;
        return (
          await getControllersRequest({
            query: {
              search: value,
              object: this.object,
              object__service_organization: isService ? id : pageOrgId
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  computed: {
    priority: {
      get() {
        return this.$store.getters.getServiceTaskCreateForm.priority;
      },
      set(newValue) {
        this.$store.commit("setServiceTaskCreateForm", {
          fieldName: "priority",
          value: newValue
        });
      }
    },
    title: {
      get() {
        return this.$store.getters.getServiceTaskCreateForm.title;
      },
      set(newValue) {
        this.$store.commit("setServiceTaskCreateForm", {
          fieldName: "title",
          value: newValue
        });
      }
    },
    description: {
      get() {
        return this.$store.getters.getServiceTaskCreateForm.description;
      },
      set(newValue) {
        this.$store.commit("setServiceTaskCreateForm", {
          fieldName: "description",
          value: newValue
        });
      }
    },
    controller: {
      get() {
        return this.$store.getters.getServiceTaskCreateForm.controller;
      },
      set(newValue) {
        this.$store.commit("setServiceTaskCreateForm", {
          fieldName: "controller",
          value: newValue
        });
      }
    },
    object: {
      get() {
        return this.$store.getters.getServiceTaskCreateForm.object;
      },
      set(newValue) {
        this.$store.commit("setServiceTaskCreateForm", {
          fieldName: "object",
          value: newValue
        });
      }
    },
    task_type: {
      get() {
        return this.$store.getters.getServiceTaskCreateForm.task_type;
      },
      set(newValue) {
        this.$store.commit("setServiceTaskCreateForm", {
          fieldName: "task_type",
          value: newValue
        });
      }
    },
    deadline_at: {
      get() {
        return this.$store.getters.getServiceTaskCreateForm.deadline_at;
      },
      set(newValue) {
        this.$store.commit("setServiceTaskCreateForm", {
          fieldName: "deadline_at",
          value: newValue
        });
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("clearCreateServiceTaskForm");
  },
  methods: {
    createTask() {
      this.loading = true;
      this.$store
        .dispatch("createTask")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.serviceTask)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
